/*
 * ---------------------------------------------------------------------
 * global veu constants
 * ---------------------------------------------------------------------
 */
// legal System states
export const SYSTEM_STATE = {
    INIT: "initialize",
    SETUP: "setup",
    READY: "ready",
    ERROR: "error",
    MAINTENANCE: "maintenance"
};

// legal app states
// as array we allways need to extend it
export const APP_STATES = [
    "init-event",
    "init-i18n",
    "sso-plazz",
    "check-session",
    "init-page",
    "ready",
    "internal-error",
    //"serverdown", // needs implementation
];

// same values as in backend constants
export const EVENT_PAGE_TYPE = {
    HOME: "home",
    PREREGISTER: "preregister",
    GLOBAL: "global",
    CONTENT: "content",
    TILESMENU: "tilesmenu",
}

export const VEU_CORE_VERSION = "2.1.0";

export const VEU_NAME = "VEU visitors-core";
export const VEU_COPYRIGHT = "(c)2020";
export const VEU_AUTHOR = "SEVEN M GmbH";
export const VEU_AUTHOR_URL = "https://seven-m.de";
