const MATOMO_DEV = {
    baseUrl: "https://matomo.sevenm.de/matomo/",
    siteId: 5,
    visitDimensions: {
        eventId: 2,
        langId: 5,
    },
    actionDimensions: {
        isLoggedInId: 6,
    }
};

const MATOMO_TEST = {
    baseUrl: "https://matomo.sevenm.de/matomo/",
    siteId: 5,
    visitDimensions: {
        eventId: 2,
        langId: 1,
    },
    actionDimensions: {
        isLoggedInId: 5,
    }
};

const MATOMO_STAGE = {
    baseUrl: "https://matomo.sevenm.de/matomo/",
    siteId: 5,
    visitDimensions: {
        eventId: 2,
        langId: 1,
    },
    actionDimensions: {
        isLoggedInId: 5,
    }
};

const MATOMO_LIVE = {
    baseUrl: "https://matomo.sevenm.de/matomo/",
    siteId: 5,
    visitDimensions: {
        eventId: 2,
        langId: 1,
    },
    actionDimensions: {
        isLoggedInId: 5,
    }
};


export const getMatomoConfig = () => {
    if (process.env.REACT_APP_DEPLOY_ENV === "development")
        return MATOMO_DEV;

    if (process.env.REACT_APP_DEPLOY_ENV === "test")
        return MATOMO_TEST;

    if (process.env.REACT_APP_DEPLOY_ENV === "stage")
        return MATOMO_STAGE;

    if (process.env.REACT_APP_DEPLOY_ENV === "live")
        return MATOMO_LIVE;

    console.error("ERROR REACT_APP_DEPLOY_ENV is undefined");

    return null;
}
